import 'shared-components/styles/preflight.css'
import 'shared-components/styles/globals.css'
import 'shared-components/styles/freestar.css'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { gtmDatalayer } from 'shared-code/gtm'
import Head from 'next/head'
import TakeoverScript from 'shared-components/ad/TakeoverScript'
import stylesUtils from 'shared-components/styles/util.module.css'

const SoundGuysApp: React.VFC<AppProps> = ({ Component, pageProps }) => (
  <>
    <Head>
      <TakeoverScript className={stylesUtils.takeoverOn} />
    </Head>
    <Component {...pageProps} />
  </>
)

export default SoundGuysApp

export function reportWebVitals({ id, label, value, name }: NextWebVitalsMetric): void {
  void gtmDatalayer({
    event: 'web-vitals',
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    event_action: name,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    // The 'id' value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    non_interaction: true, // avoids affecting bounce rate.
  })
}
